import {
  Feature,
  FeatureCollection,
  GeometryCollection,
  Point,
  Polygon,
} from '@turf/turf';
import * as turf from '@turf/turf';
import { kml } from '@tmcw/togeojson';
const GeographicService = {
  getCenter(content: Feature | FeatureCollection): Feature<Point> {
    return turf.centerOfMass(content);
  },
  isInside(geometry: turf.Geometry, point: number[]) {
    return turf.inside(
      {
        type: 'Point',
        coordinates: point,
      },
      geometry as Polygon
    );
  },
  async getFeatureCollection(plainText: any): Promise<FeatureCollection> {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, 'text/xml');
    const result = await kml(xmlDoc);
    return result;
  },
};

export default GeographicService;

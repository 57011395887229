import GeographicService from './geo';
import { getKMLData } from './kmz';

const HotspotsService = {
  async parseHotspots(KMZFileContentsAsString: string) {
    function str2ab(str: string) {
      var buf = new ArrayBuffer(str.length);
      var bufView = new Uint8Array(buf);
      for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      return buf;
    }
    const kmlData = getKMLData(str2ab(KMZFileContentsAsString));
    const result = await GeographicService.getFeatureCollection(kmlData);
    return result;
  },
};

export default HotspotsService;

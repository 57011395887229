import { SurveyWrapper } from './survey';

export interface Submission {
  surveyWrapper: SurveyWrapper;
  countID: string;
  organizationID: string;
  uploaded: boolean;
}

export enum SubmissionResult {
  Success = "Success",
  NoInternet = "NoInternet",
  DocFull = "DocFull",
  Failure = "Failure",
}

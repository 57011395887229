import { FirebaseService } from '@/services/firebase';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { Submission } from './models/submission';
import { Count, CountPayload, RegionMap, Shelter } from './models/count';
import { Survey, FormType, SurveyWrapper } from './models/survey';
import { SurveyService } from '@/services/survey';
import { Team } from './models/Organization';
import HotspotsService from './services/hotspots';
import { Feature, Geometry, GeometryCollection, Properties } from '@turf/turf';
import _ from 'lodash';

Vue.use(Vuex);
const randomStart = Math.floor(Math.random() * 100);
const state = {
  count: null as Count | null,
  surveys: [],
  useCustomLocation: false,
  customLocation: '',
  user: null,
  firebaseUser: null,
  survey: '',
  organizationID: '',
  organizationName: '',
  surveyStructures: {} as any,
  volunteerName: '',
  volunteerPhone: '',
  countID: '',
  language: 'default',
  identifier: '',
  docNumber: randomStart,
  location: null as number[] | null,
  team: null as null | Team,
  hotspots: [] as Feature<Geometry | GeometryCollection, Properties>[],
  hotspotsSeparate: [] as Feature<Geometry | GeometryCollection, Properties>[],
  shelter: null as null | Shelter,
  teamSetByAdmin: false,
};

function getEmptyState() {
  return _.cloneDeep(state);
}

type State = typeof state;

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: getEmptyState(),
  mutations: {
    setShelter(state: State, shelter: Shelter) {
      state.shelter = shelter;
    },
    setTeam(state: State, team: Team) {
      state.team = team;
      state.teamSetByAdmin = false;
    },
    setTeamName(state: State, name: string) {
      const map = state.count?.regionMap;
      if (!map) return;
      const teams = map.regions.features;
      if (!teams) return;
      const team = teams.find(t => {
        return !!t.properties && t.properties.name === name
      });
      if (!team) return;
      state.teamSetByAdmin = true;
      state.team = team as unknown as Team;
    },
    updateLocation(state: State, location: number[]) {
      state.location = location;
    },
    clearLocation(state: State) {
      state.location = null;
    },
    customLocation(state: State, value: string) {
      state.customLocation = value;
    },
    customLocationToggleChanged(state: State, value: boolean) {
      state.useCustomLocation = value;
    },
    removeSubmitted(state: State, justSubmitted: SurveyWrapper[]) {
      const submittedIDs: string[] = justSubmitted.map(js => js.householdID);
      const all: Submission[] = state.surveys;
      for (const submittedID of submittedIDs) {
        const found = all.find(
          (s: Submission) => s.surveyWrapper.householdID === submittedID
        );
        if (!found) continue;

        delete (found as any).surveyWrapper.survey;
        delete (found as any).surveyWrapper.location;
        delete found.surveyWrapper.customLocation;
        found.uploaded = true;
      }
    },
    language(state: State, language: string) {
      state.language = language;
    },
    addSurvey(state: State, survey: SurveyWrapper) {
      const submission: Submission = {
        surveyWrapper: survey,
        countID: state.countID,
        organizationID: state.organizationID,
        uploaded: false,
      };
      (state.surveys as Submission[]).push(submission);
    },
    setUser(state: State, { name, phone }) {
      state.volunteerName = name;
      state.volunteerPhone = phone;
    },
    setFirebaseUser(state: State, user: any) {
      state.firebaseUser = user;
    },
    setCount(
      state: State,
      {
        count,
        countID,
        organizationID,
      }: { count: CountPayload; countID: string; organizationID: string }
    ) {
      const isUpdatingSame = state.countID === countID;
      if (!isUpdatingSame) {
        state.docNumber = randomStart;
        state.team = null;
        state.shelter = null;
        state.hotspots = [];
        state.hotspotsSeparate = [];
      }
      const newSurveyStructures: any = { Standard: count.interviewSurvey };

      if (count.observationalSurvey) {
        newSurveyStructures[FormType.PITCountObservation] =
          count.observationalSurvey;
      }

      if (count.volunteerSignupSurvey) {
        newSurveyStructures[FormType.VolunteerSignup] =
          count.volunteerSignupSurvey;
      }

      state.surveyStructures = newSurveyStructures;
      state.organizationID = organizationID;
      state.organizationName = count.organizationName;

      const { regionMap, hotspots, ...countData } = count;

      const newCount: Count = {
        ...countData,
        ...(regionMap && {
          regionMap: JSON.parse(regionMap) as RegionMap,
        }),
      };

      state.count = newCount;
      state.countID = countID;

      if (count.hotspots)
        HotspotsService.parseHotspots(count.hotspots).then(hs => {
          state.hotspots = hs.features;
        });
      else state.hotspots = [];

      if (!isUpdatingSame) {
        FirebaseService.listenToCount();
        FirebaseService.listenToHotspots();
      }
    },
    setHotspots(
      state: State,
      {
        hotspots,
        countID,
        organizationID,
      }: { hotspots: { hotspots: string }; countID: string; organizationID: string }
    ) {
      HotspotsService.parseHotspots(hotspots.hotspots).then(hs => {
        state.hotspotsSeparate = hs.features;
      });
    },
    clearUser(state: State) {
      const newState = getEmptyState();
      newState.surveys = state.surveys;
      Object.assign(state, newState);
      FirebaseService.logout();
    },
    incrementDocNumber(state: State) {
      state.docNumber++;
    },
  },
  getters: {
    teamSetByAdmin(state: State) {
      return state.teamSetByAdmin;
    },
    firebaseUser(state: State) {
      return state.firebaseUser;
    },
    shelter(state: State) {
      return state.shelter;
    },
    count(state: State) {
      return state.count;
    },
    hasShelters(state: State) {
      return state.count && !!state.count.shelters;
    },
    shelters(state: State) {
      return state.count?.shelters;
    },
    hotspots(state: State) {
      if (!!state.hotspotsSeparate && state.hotspotsSeparate.length > 0) return state.hotspotsSeparate;
      return state.hotspots;
    },
    team(state: State): Team | null {
      return state.team;
    },
    regionMap(state: State): RegionMap | undefined {
      return state.count?.regionMap;
    },
    coc(state: State): string | undefined {
      return state.count?.coc;
    },
    location(state: State): number[] | null {
      return state.location;
    },
    useCustomLocation(state: State): boolean {
      return state.useCustomLocation;
    },
    hasObservation(state: State): boolean {
      return FormType.PITCountObservation in state.surveyStructures;
    },
    identifier(state: State): string {
      if (!state.identifier) {
        state.identifier = SurveyService.getUUID();
      }
      return state.identifier;
    },
    language(state: State): string {
      return state.language;
    },
    organizationLoaded(state: State): boolean {
      return (
        state.organizationID != '' &&
        state.organizationName != '' &&
        state.countID != ''
      );
    },
    countID(state: State): string {
      return state.countID;
    },
    organizationID(state: State): string {
      return state.organizationID;
    },
    volunteerName(state: State): string {
      return state.volunteerName;
    },
    volunteerPhone(state: State): string {
      return state.volunteerPhone;
    },
    surveys(state: State): Submission[] {
      return state.surveys;
    },
    unsubmittedSurveys(state: State): Submission[] {
      const unsubmitted = state.surveys.filter((s: Submission) => !s.uploaded);
      return unsubmitted;
    },
    loggedIn(state: State) {
      return state.volunteerName != '' && state.volunteerPhone != '';
    },
    user(state: State) {
      return state.user;
    },
    survey(state: State) {
      return state.survey;
    },
    customLocation(state: State) {
      return state.customLocation;
    },
    organizationName(state: State) {
      return state.organizationName;
    },
    surveyStructure: (state: State) => (surveyType: string) => {
      if (!(surveyType in state.surveyStructures)) {
        return null;
      }
      return state.surveyStructures[surveyType];
    },
    countName(state: State) {
      return state.count?.name;
    },
    docNumber(state: State) {
      return state.docNumber;
    },
  },
});

import { Survey, FormType, SurveyWrapper } from '@/models/survey';
import Store from '@/store';
import {v4 as uuidv4} from 'uuid';

export interface SurveyStructure {
  title: string;
  pages: Page[];
}

export interface Page {
  name: string;
  elements: Element[];
}

export interface Element {
  type: string;
  name: string;
  title: string | Multilanguage;
  choices: (Choice | string)[];
  templateElements: Element[];
}

export interface Multilanguage {
  default: string;
  es: string;
}

export interface Choice {
  value: string;
  text: string | Multilanguage;
}


export const SurveyService = {
  getUUID(): string {
    return uuidv4();
  },
  addPersonalIDs(survey: Survey) {
    survey['Personal ID'] = this.getUUID();
    if (survey.HouseholdMembers) {
      for (let member of survey.HouseholdMembers) {
        member['Personal ID'] = this.getUUID();
      }
    }
    return survey;
  },
  async wrapSurvey(
    survey: Survey,
    type: FormType,
    shelter: string | undefined
  ): Promise<SurveyWrapper> {
    const countMode = Store.getters.count.mode;
    const surveyStatus = 
      countMode === "Live" ? 'Live' : 'Training';
    survey = this.addPersonalIDs(survey);
    const toReturn: SurveyWrapper = {
      location: Store.getters.location,
      date: Date.now(),
      survey,
      volunteerName: Store.getters.volunteerName,
      volunteerPhone: Store.getters.volunteerPhone,
      identifier: Store.getters.identifier,
      householdID: this.getUUID(),
      type,
      version: 2,
      surveyStatus,
    };
    if (Store.getters.useCustomLocation)
      toReturn.customLocation = Store.getters.customLocation;
    if (Store.getters.team)
      toReturn.teamName = Store.getters.team?.properties?.name;
    if (shelter) toReturn.shelter = shelter;
    return toReturn;
  },
  getLanguages(surveyStructure: any): string[] {
    let languages = {};
    const structure = JSON.parse(surveyStructure);

    function checkElement(element: Element): void {
      const title = element.title as Multilanguage;
      if (title && title.default) {
        languages = { ...languages, ...title };
      }

      if (element.choices) {
        for (const choice of element.choices) {
          if (
            choice &&
            (choice as Choice).text &&
            ((choice as Choice).text as Multilanguage).default
          ) {
            const c = choice as Choice;
            const t = c.text as Multilanguage;
            languages = { ...languages, ...t };
          }
        }
      }
      if (element.templateElements) {
        for (const templateElement of element.templateElements) {
          checkElement(templateElement);
        }
      }
    }

    function check(obj: SurveyStructure) {
      for (const page of obj.pages) {
        if (!page.elements) continue;
        for (const element of page.elements) {
          checkElement(element);
        }
      }
    }
    check(structure);
    return Object.entries(languages).map(o => o[0]);
  },
  hasMultipleLanguages(surveyStructure: any): boolean {
    return this.getLanguages(surveyStructure).length > 0;
  },
};

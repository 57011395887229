import 'babel-polyfill';
import '@/assets/style.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import Vue from 'vue';
import Notifications from 'vue-notification';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import prepareVuelayers from '@/plugins/vuelayers';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
prepareVuelayers();
Vue.use(Notifications);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

import { ClientEvent } from './../models/count';
import { GetCountResult, GetOrganizationIDResult } from '@/models/firebase';
import { FirebaseService } from '@/services/firebase';
import Store from '@/store';
export const CountService = {
  async setCount(countID: string): Promise<boolean> {
    const countResult = await this.getCount(countID);
    if (!countResult.success) return false;
    Store.commit('setCount', {
      count: countResult.count,
      organizationID: countResult.organizationID,
      countID,
    });
    return true;
  },
  async loginEvent(countID: string, name: string, phone: string) {
    const countResult = await this.getCount(countID);
    if (!countResult.success) return false;
    const event: ClientEvent = {
      type: 'login',
      date: Date.now(),
      identifier: Store.getters.identifier,
      volunteerName: name,
      volunteerPhone: phone,
    };
    FirebaseService.newEvent(event, countResult.organizationID, countID);
  },
  async setCountAndLoginEvent(
    countID: string,
    name: string,
    phone: string
  ): Promise<boolean> {
    const countResult = await this.getCount(countID);
    if (!countResult.success) return false;
    const event: ClientEvent = {
      type: 'login',
      date: Date.now(),
      identifier: Store.getters.identifier,
      volunteerName: name,
      volunteerPhone: phone,
    };
    FirebaseService.newEvent(event, countResult.organizationID, countID);

    Store.commit('setCount', {
      count: countResult.count,
      organizationID: countResult.organizationID,
      countID,
    });
    return true;
  },
  async getCount(countID: string): Promise<GetCountResult> {
    const firstResult: GetOrganizationIDResult = await FirebaseService.getOrganizationIDFromCountID(
      countID
    );
    if (!firstResult.success) return { success: false };
    const secondResult: GetCountResult = await FirebaseService.getCount(
      firstResult.organizationID,
      countID
    );
    return secondResult;
  },
};

export enum FormType {
  PITCountObservation = 'Observational',
  PITCountInterview = 'Standard',
  Other = 'Other',
  VolunteerSignup = 'VolunteerSignup',
}

export interface SurveyWrapper {
  survey: Survey;
  date: number;
  location: number[];
  volunteerName: string;
  volunteerPhone: string;
  identifier: string;
  customLocation?: string;
  householdID: string;
  type: FormType;
  teamName?: string;
  shelter?: string;
  version: number;
  surveyStatus: 'Live' | 'Training';
}

export interface Survey {
  Race: string | string[];
  Gender: string;
  WhereTheyStayedLastNight: string;
  Initials: string;
  Age: string;
  Hispanic: string;
  VeteranStatus: string;
  HomelessnessDuration: string;
  TimesHomelessInLastThreeYears: string;
  MonthsHomelessInLastThreeYears: string;
  HouseholdMembers: Individual[];
  UsesDrugsOrAlcohol: string;
  HasAPsychiatricCondition: string;
  HasAPhysicalDisability: string;
  WasInSpecialEd: string;
  InFacilityLessThan90Days: string;
  WhereTheyStayedBeforeInstitution: string;
  HomelessBeforeFacilityForAYearOrMore: string;
  isInvalid: number;
  whyUsingObservational: string;
  'Personal ID': string;
}

export interface Individual {
  Race: string | string[];
  Gender: string;
  WhereTheyStayedLastNight: string;
  Initials: string;
  Age: string;
  Hispanic: string;
  VeteranStatus: string;
  'Personal ID': string;
}

import Vue from 'vue';
import _ from 'lodash';
export const Notification = {
  methods: {
    send(success: boolean, title: string, text: string) {
      Vue.notify({
        group: 'main',
        title,
        text,
        type: success ? 'success' : 'warn',
      });
    },
  },
};


import { ClientEvent } from './../models/count';
import firebase, { firestore } from 'firebase';
import Store from '../store';
import { Count } from '@/models/count';
import { GetCountResult, GetOrganizationIDResult } from '../models/firebase';
import { SurveyWrapper } from '@/models/survey';
import { SubmissionResult } from '@/models/submission';
import { LoggingService } from '@/services/logging';
import { CountService } from './count';
import { Notification } from './notification';

interface VolunteerRegistration {
  answers: {
    FirstName: string;
    LastName: string;
  };
  phone: string;
  team?: string;
}

export const FirebaseService = {
  async listenToRegistration() {
    const db = firebase.firestore();
    const organizationID = Store.getters.organizationID;
    const countID = Store.getters.countID;
    const userID = firebase.auth().currentUser?.uid;
    if (!organizationID || !countID || !userID) {
      return false;
    }
    const path = `/organizations/${organizationID}/counts/${countID}/volunteerRegistration/${userID}`;
    return await db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        const registration: VolunteerRegistration = snapshot.data() as any;
        if (!registration) return;
        const name = `${registration.answers.FirstName} ${registration.answers.LastName}`;
        Store.commit('setUser', {
          name,
          phone: firebase.auth().currentUser?.phoneNumber,
        });
        if (registration.team) {
          Store.commit('setTeamName', registration.team);
        }
        // Ideally we wouldn't fire a login event every time this doc updates.
        CountService.loginEvent(
          Store.getters.countID,
          name,
          firebase.auth().currentUser?.phoneNumber as string
        );
      });
  },
  async listenToCount() {
    const db = firebase.firestore();
    const organizationID = Store.getters.organizationID;
    const countID = Store.getters.countID;
    if (!organizationID || !countID) return;
    const path = `organizations/${organizationID}/counts/${countID}`;
    return await db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        const count = snapshot.data();
        if (!count) return;
        Store.commit('setCount', {
          count: count,
          organizationID: organizationID,
          countID,
        });
      });
  },
  async listenToHotspots() {
    const db = firebase.firestore();
    const organizationID = Store.getters.organizationID;
    const countID = Store.getters.countID;
    if (!organizationID || !countID) return;
    const path = `organizations/${organizationID}/counts/${countID}/hotspots/all`;
    return await db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        const hotspots = snapshot.data();
        if (!hotspots) return;
        Store.commit('setHotspots', {
          hotspots,
          organizationID: organizationID,
          countID,
        });
      });
  },
  async getRegistration() {
    const db = firebase.firestore();
    const organizationID = Store.getters.organizationID;
    const countID = Store.getters.countID;
    const userID = firebase.auth().currentUser?.uid;
    if (!organizationID || !countID || !userID) {
      return false;
    }
    const path = `/organizations/${organizationID}/counts/${countID}/volunteerRegistration/${userID}`;
    const doc = await db.doc(path).get();
    if (!doc.exists) {
      return false;
    }
    return doc.data();
  },
  async getCoCShapefileUrl(coc: string) {
    const cocForUrl = coc.replace('-', '_');
    const storage = firebase.storage();
    const url = await storage
      .ref(`coc_boundary/${cocForUrl}.geojson`)
      .getDownloadURL();
    return url;
  },
  async getOrganizationIDFromCountID(
    countID: string
  ): Promise<GetOrganizationIDResult> {
    const doc = await getDoc('counts', countID);
    if (!doc) return { organizationID: '', success: false };
    if (!doc || !doc.organizationID)
      return { organizationID: '', success: false };
    return { organizationID: doc.organizationID, success: true };
  },
  async getCount(
    organizationID: string,
    countID: string
  ): Promise<GetCountResult> {
    const doc = await getDoc(`organizations/${organizationID}/counts`, countID);
    if (!doc)
      return {
        success: false,
      };

    return { count: doc as Count, success: true, organizationID };
  },
  async newEvent(event: ClientEvent, organizationID: string, countID: string) {
    const db = firebase.firestore();
    const path = `/organizations/${organizationID}/counts/${countID}/events/0`;
    const doc = db.doc(path);
    try {
      await doc.set(
        { events: firebase.firestore.FieldValue.arrayUnion(event) },
        { merge: true }
      );
    } catch (error) {
      LoggingService.Log(error);
      return false;
    }
    return true;
  },
  async submitSurveys(
    surveys: SurveyWrapper[],
    countID: string,
    organizationID: string
  ): Promise<SubmissionResult> {
    if (!navigator.onLine) {
      Notification.methods.send(false, 'No internet', `We'll keep trying in the background.`)
    }
    const db = firebase.firestore();
    const contentResult = await this.submitContent(
      surveys,
      countID,
      organizationID,
      db
    );
    if (contentResult !== SubmissionResult.Success) return contentResult;
    return SubmissionResult.Success;
  },
  async submitContent(
    surveys: SurveyWrapper[],
    countID: string,
    organizationID: string,
    db: firebase.firestore.Firestore
  ) {
    this.writeToBackup(surveys, countID, organizationID, db);

    const docNumber = Store.getters.docNumber;
    LoggingService.Log(`found doc # ${docNumber}`);
    const path = `/organizations/${organizationID}/counts/${countID}/submissions/${docNumber}`;
    const doc = db.doc(path);
    try {
      await doc.set(
        { surveys: firebase.firestore.FieldValue.arrayUnion(...surveys) },
        { merge: true }
      );


    } catch (error) {
      LoggingService.Log(error);
      Store.commit('incrementDocNumber');
      return SubmissionResult.DocFull;
    }
    return SubmissionResult.Success;
  },
  async writeToBackup(
    surveys: SurveyWrapper[],
    countID: string,
    organizationID: string,
    db: firebase.firestore.Firestore
  ) {
    // TODO: For each survey, set the value of
    // `/organizations/${organizationID}/counts/${countID}/submissionsBackup/${householdID}`;
    // to the survey object.
    // Make sure that if this fails (or succeeds) that we don't have any impact on the other one.
    // Do it async and first, and catch any errors I guess?
    const path = `/organizations/${organizationID}/counts/${countID}/submissionsBackup`;
    for (const survey of surveys) {
      const doc = db.doc(`${path}/${survey.householdID}`);
      try {
        await doc.set(survey);
      } catch (error) {
        LoggingService.Log('Failure writing to backup location' + error);
      }
    }
  },
  async submitVolunteerRegistration(answers: any) {
    const organizationID = Store.getters.organizationID;
    const countID = Store.getters.countID;
    const userID = firebase.auth().currentUser?.uid;
    const phone = firebase.auth().currentUser?.phoneNumber;
    if (!organizationID || !countID || !userID) {
      return false;
    }
    const path = `/organizations/${organizationID}/counts/${countID}/volunteerRegistration/${userID}`;
    const db = firebase.firestore();
    const doc = db.doc(path);
    try {
      await doc.set({ answers, phone, date: Date.now() });
    } catch (err) {
      console.log(err);
      return false;
    }
    return true;
  },
  async logout() {
    firebase.auth().signOut();
  },
  async submitLiveToggle(
    surveys: SurveyWrapper[],
    countID: string,
    organizationID: string,
    db: firebase.firestore.Firestore
  ) {
    try {
      const householdIDs = surveys.map(s => s.householdID);
      const path = `/organizations/${organizationID}/counts/${countID}/productionSurveys/surveys`;
      const doc = db.doc(path);
      await doc.set(
        { surveys: firebase.firestore.FieldValue.arrayUnion(...householdIDs) },
        { merge: true }
      );
    } catch (error) {
      // Assuming this will only fail if isLive is false. If that's the case, then we don't want to save the IDs here.
      // That's how we're tracking when a survey was submitted in live mode or not.
      // This is how we could get that data if we wanted to:
      // const isLive = (await FirebaseService.getCount(organizationID, countID)).count.isLive;
    }
  },
};

async function getDoc(
  collection: string,
  id: string
): Promise<firebase.firestore.DocumentData | undefined> {
  const db = firebase.firestore();
  const doc = await db
    .collection(collection)
    .doc(id)
    .get();
  return await doc.data();
}

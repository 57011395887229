import Vue from 'vue';
import Router from 'vue-router';
import Store from './store';

Vue.use(Router);

function guard(to: any, from: any, next: any) {
  if (Store.getters.loggedIn && Store.getters.organizationLoaded) {
    next();
  } else {
    next('/login');
  }
}

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    beforeEnter: guard,
    component: () => import('./components/Home.vue'),
  },
  {
    path: '/new/:surveyType/:language',
    name: 'new',
    beforeEnter: guard,
    component: () => import('./components/Survey.vue'),
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./components/Login/Login.vue'),
  },
  {
    path: '/location',
    name: 'location',
    beforeEnter: guard,
    component: () => import('./components/Location.vue'),
  },
  {
    path: '/start',
    name: 'start',
    beforeEnter: guard,
    component: () => import('./components/Start.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: guard,
    component: () => import('./components/Settings.vue'),
  },
  {
    path: '/map',
    name: 'map',
    beforeEnter: guard,
    component: () => import('./components/MapScreen.vue'),
  },
  {
    path: '/submissions',
    name: 'submissions',
    component: () => import('@/components/Submissions.vue'),
  },
  {
    path: '/*',
    name: '404',
    component: () => import('./components/FourOhFour.vue'),
  },
];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

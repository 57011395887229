
import { Submitter } from './services/submitter';
import firebase, { firestore } from 'firebase';
import { Submission } from '@/models/submission';
import Store from '@/store';
import { FirebaseService } from './services/firebase';
import Vue from 'vue';
export default Vue.extend({
  name: 'App',
  components: {},
  data(): { listener: any; volunteerRegistrationListener: any, hotspotsListener: any} {
    return {
      listener: null as any,
      volunteerRegistrationListener: null as any,
      hotspotsListener: null as any,
    };
  },
  created(): void {
    const localFirebase = process.env.VUE_APP_LOCAL_FIREBASE;
    if (localFirebase === 'true') {
      const app = firebase.initializeApp({
        projectId: 'premium-oven-240901',
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      });
      app.firestore().settings({ host: 'localhost:8080', ssl: false });
      app.functions().useFunctionsEmulator('http://localhost:5001');
    } else {
      const config = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
      };
      firebase.initializeApp(config);
    }
    this.listener = FirebaseService.listenToCount();
    this.hotspotsListener = FirebaseService.listenToHotspots();
    firebase.auth().onAuthStateChanged(() => {
      this.volunteerRegistrationListener = FirebaseService.listenToRegistration();
    });
  },
  methods: {
  },
});
